import {React,useState} from "react";
import { Formik, Form, useField} from "formik";
import * as Yup from "yup";
import {InteractionRequiredAuthError} from '@azure/msal-browser';
import {useMsal}from '@azure/msal-react';

const addPosts = async (values,setProgress,token) => {
  var today=new Date();
  var dateFormatted=String(today.getUTCFullYear())+String((today.getUTCMonth()+1))+String(today.getUTCDate())+String(today.getUTCHours())+String(today.getUTCMinutes())+String(today.getUTCSeconds());
  values.time=dateFormatted;
  await fetch('https://j1744g3h2e.execute-api.us-east-1.amazonaws.com/prod/survey/Survey'+dateFormatted, {
  method: 'PUT',
  body: JSON.stringify(values,null,2),
  headers: {
   'Content-type': 'application/json; charset=UTF-8','authorization':token
  }
  })
  .then((response) => {
	if(response.ok) {
	} else {
	}
  })
  .then((data) => {
	  setProgress(100); 
	  document.getElementsByName("ProgressLabel")[0].style.visibility = "visible"
          document.getElementsByName("ProgressError")[0].style.visibility = "hidden"
  })
  .catch((err) => {
	  document.getElementsByName("ProgressLabel")[0].style.visibility ="hidden"
	  setProgress(100);
          document.getElementsByName("ProgressError")[0].style.visibility ="visible"
 });
}

const AquireAccess= (instance, account,values,setProgress) => {	
    instance.acquireTokenSilent({scopes: ["https://gnsgvoportal.accenture.com/GVO.Role"],account: account}).then(tokenResponse => {
    addPosts(values,setProgress,tokenResponse.accessToken);
    return(tokenResponse);
}).catch(error => {
    if (error instanceof InteractionRequiredAuthError) {
        // fallback to interaction when silent call fails
            console.log("failed: ");
    }
	console.log(error);
    // handle other errors
});}

const MyTextInput = ({ label, ...props }) => {
  const [field, meta] = useField(props);
  return (
    <>
      <label htmlFor={props.id || props.name}>{label}</label>
      <input className="text-input" {...field} {...props} />
      {meta.touched && meta.error ? (
        <div className="error">{meta.error}</div>
      ) : null}
    </>
  );
};

const MyTextArea = ({ label, ...props }) => {
  const [field, meta] = useField(props);
  return (
    <>
      <label htmlFor={props.id || props.name} style={{marginLeft: "2rem"}}>{label}</label>
      <textarea className="text-area" {...field} {...props} />
      {meta.touched && meta.error ? (
        <div className="error">{meta.error}</div>
      ) : null}
    </>
  );
};

const MyRadio = ({ ...props }) => {
  const [field, meta] = useField({ ...props, type: "radio" });
  return (
    <div>
      <label className="radio-input">
        <input type="radio" {...field} {...props} />
        {props.label}
      </label>
      {meta.touched && meta.error ? (
        <div className="error">{meta.error}</div>
      ) : null}
    </div>
  );
};

const MyCheckbox = ({ children, ...props }) => {
  const [field, meta] = useField({ ...props, type: "checkbox" } );
  return (
    <div>
      <label className="checkbox-input">
        <input type="checkbox" {...field} {...props} onClick={props.callback()}/>
        {children}
      </label>
      {meta.touched && meta.error ? (
        <div className="error">{meta.error}</div>
      ) : null}
    </div>
  );
};

const SurveyForm = () => {
	const [progress, setProgress] = useState(0);
	const { instance } = useMsal();
	const activeAccount = instance.getActiveAccount();
     var username="";
	if (activeAccount){
			username=activeAccount.name;
	} else {
		return(<h3> Please sign in to take the survey</h3>)
	}	

const genericfeedback = (props) => {
	if (document.getElementsByName("genericFeedback")[0].checked)
	 {
	   for (let i = 0; i < document.getElementsByName("question1").length; i++) {
		document.getElementsByName("question1")[i].disabled = true
  		document.getElementsByName("question2")[i].disabled = true
  		document.getElementsByName("question3")[i].disabled = true

		}
  		document.getElementsByName("instance")[0].disabled = true



 	} else {
	   for (let i = 0; i < document.getElementsByName("question1").length; i++) {
  		document.getElementsByName("question1")[i].disabled = false
  		document.getElementsByName("question2")[i].disabled = false
  		document.getElementsByName("question3")[i].disabled = false
		}
  		document.getElementsByName("instance")[0].disabled = false
 	}

}


  return (
    <>
      <Formik
	  initialValues={{
	  time:"",
          enterpriseID: {username}.username,
          instance: "",
          genericFeedback: false,
          question1: "",
	  question2: "",
	  question3: "",
	  Newcomment: "",
        }}
        validationSchema={Yup.object({
          instance: Yup.string().when(
		"genericFeedback",{
		is: false,
		then: () => Yup.string().required("Required").max(12, "Must be 12 characters or less").matches(/^[RIri]+[INin]+[TCtc]+[Mm0-9]+[0-9]+$/,"must be a Incident/SR")
		}),

          Newcomment: Yup.string().max(1000, "Must be 1000 characters or less and no special characters").matches(/^[0-9a-zA-Z]+[0-9a-zA-Z. ]+$/,"no special characters allowed"),
	  engineer: Yup.string().max(25,"enter the Enterprise ID of the Engineer").matches(/^[a-zA-Z][a-zA-Z.]+$/,"enter the Enterprise ID of the Engineer").required("Required"),
        })}
        onSubmit={(values, { setSubmitting }) => {
		AquireAccess(instance,activeAccount,values,setProgress);
   	       
		setProgress(null);
		document.getElementsByName("ProgressForm")[0].style.visibility = "visible"
		for (let i = 0; i < document.getElementsByName("question1").length; i++) {
	                document.getElementsByName("question1")[i].disabled = true
        	        document.getElementsByName("question2")[i].disabled = true
	                document.getElementsByName("question3")[i].disabled = true
		}
		document.getElementsByName("Newcomment")[0].disabled = true
		document.getElementsByName("SubmitButton")[0].disabled = true
		document.getElementsByName("genericFeedback")[0].disabled = true
		document.getElementsByName("instance")[0].disabled = true

		setSubmitting(false);
        }}
      >
        <Form style={{backgroundImage: "url(/Background.png)",backgroundSize: "cover",backgroundRepeat: "no-repeat"}}>
          <br />
	  <div style={{ display: "flex", flexDirection:"row",marginLeft: "2rem" }}>
	  <MyTextInput
            label=" This feedback is for Incident / Service request: "
            name="instance"
            type="text"
            placeholder="INC or RITM"
	    id="instance"
          />
	  <MyCheckbox name="genericFeedback" callback={()=> genericfeedback}>
            I am providing a generic feedback, no INC or RITM number
          </MyCheckbox>
	  </div>
          <br />
	  <div style={{marginLeft: "2rem"}}>
	  <MyTextInput
            label=" This feedback is for Engineer: "
            name="engineer"
            type="text"
            placeholder="enterprise ID"
            id="engineer"
          />
	  </div>
          <br />
          <br />
          <div style={{ display: "flex", flexDirection: "row" }}>
            <label style={{ maxWidth: "300px",marginLeft: "2rem" }}>
              Please rate on a scale of 1 (not satisfied) to 5 (Satisfied)
            </label>
            <div style={{ marginLeft: "1.5rem" }}>Not Satisfied ~~~~~~~~~~~~ Satisfied</div>
            <label> </label>
          </div>
          <br />
          <div style={{ display: "flex", flexDirection: "row" }}>
            <label style={{ maxWidth: "300px",marginLeft: "2rem" }}>
              1. How satisfied are you with the resolution provided?
            </label>
            <div style={{ marginLeft: "1.5rem" }}>
              <MyRadio name="question1" label="1" value={"1"}></MyRadio>
            </div>
            <div style={{ marginLeft: "1.5rem" }}>
              <MyRadio name="question1" label="2" value={"2"}></MyRadio>
            </div>
            <div style={{ marginLeft: "1.5rem" }}>
              <MyRadio name="question1" label="3" value={"3"}></MyRadio>
            </div>
            <div style={{ marginLeft: "1.5rem" }}>
              <MyRadio name="question1" label="4" value={"4"}></MyRadio>
            </div>
            <div style={{ marginLeft: "1.5rem" }}>
              <MyRadio name="question1" label="5" value={"5"}></MyRadio>
            </div>
            <div style={{ marginLeft: "1.5rem" }}>
              <MyRadio name="question1" label="Not Applicable" value={"NA"}></MyRadio>
            </div>
          </div>
          <br />
          <div style={{ display: "flex", flexDirection: "row" }}>
            <label style={{ maxWidth: "300px", marginLeft: "2rem" }}>
              2. How is the Quality of the updates shared? (were timely and
              relevant updates provided)
            </label>
            <div style={{ marginLeft: "1.5rem" }}>
              <MyRadio name="question2" label="1" value={"1"}></MyRadio>
            </div>
            <div style={{ marginLeft: "1.5rem" }}>
              <MyRadio name="question2" label="2" value={"2"}></MyRadio>
            </div>
            <div style={{ marginLeft: "1.5rem" }}>
              <MyRadio name="question2" label="3" value={"3"}></MyRadio>
            </div>
            <div style={{ marginLeft: "1.5rem" }}>
              <MyRadio name="question2" label="4" value={"4"}></MyRadio>
            </div>
            <div style={{ marginLeft: "1.5rem" }}>
              <MyRadio name="question2" label="5" value={"5"}></MyRadio>
            </div>
            <div style={{ marginLeft: "1.5rem" }}>
              <MyRadio name="question2" label="Not Applicable" value={"NA"}></MyRadio>
            </div>
          </div>
          <br />
          <div style={{ display: "flex", flexDirection: "row" }}>
            <label style={{ maxWidth: "300px" , marginLeft: "2rem"}}>
              3. How would you rate the proficiency and professionalism of the
              Engineer?
            </label>
            <div style={{ marginLeft: "1.5rem" }}>
              <MyRadio name="question3" label="1" value={"1"}></MyRadio>
            </div>
            <div style={{ marginLeft: "1.5rem" }}>
              <MyRadio name="question3" label="2" value={"2"}></MyRadio>
            </div>
            <div style={{ marginLeft: "1.5rem" }}>
              <MyRadio name="question3" label="3" value={"3"}></MyRadio>
            </div>
            <div style={{ marginLeft: "1.5rem" }}>
              <MyRadio name="question3" label="4" value={"4"}></MyRadio>
            </div>
            <div style={{ marginLeft: "1.5rem" }}>
              <MyRadio name="question3" label="5" value={"5"}></MyRadio>
            </div>
            <div style={{ marginLeft: "1.5rem" }}>
              <MyRadio name="question3" label="Not Applicable" value={"NA"}></MyRadio>
            </div>
          </div>
          <br />
	  <label style={{marginLeft: "15rem",position:"absolute",textAlign:"center",display:"block"}}><b>Suggestions:</b></label>
	  <MyTextArea style={{marginLeft: "20rem"}}
            label=""
            name="Newcomment"
	    rows={6}
	    cols={40}
	    placeholder="please share any additional feedback or improvement areas for us!"
          />
          <br />
          <br />
          <p><button type="submit" name="SubmitButton" style={{ marginLeft: "1.5rem" }}>Submit Survey</button>
  	  <progress value={progress} max="100" id="ProgressForm" name="ProgressForm" style={{ marginLeft: "1.5rem", visibility:"hidden" }} />
	  <label name="ProgressLabel" style={{marginLeft:"0.3rem", visibility:"hidden"}}>Thank you, your feedback has been Submitted</label>
	  <label name="ProgressLabelEngineer" style={{marginLeft:"0.3rem", visibility:"hidden"}}>Engineer name is a mandatory field</label>
          <label name="ProgressError" style={{marginLeft:"0.3rem", visibility:"hidden"}}>We have a problem submitting the feedback now, please submit again</label></p>
          <br />
	  <br />
        </Form>
      </Formik>
    </>
  );
}
export function Survey(){
	return (
		<SurveyForm />
	);
}

