import { LogLevel } from '@azure/msal-browser';

export const msalConfig = {
    auth: {
/*        clientId: 'bc8310e0-d2a8-4d13-93c0-88b8ab51920d',*/
	  clientId: 'e1c91a20-b1f2-4aec-a8c8-593a139cab9d',
        authority:'https://login.microsoftonline.com/e0793d39-0939-496d-b129-198edd916feb/', 
        redirectUri: '/', 
        postLogoutRedirectUri: '/', 
        navigateToLoginRequestUrl: false, 
    },
    cache: {
        cacheLocation: 'localStorage', 
        storeAuthStateInCookie: false, 
    },
    system: {
        loggerOptions: {
            loggerCallback: (level, message, containsPii) => {
                if (containsPii) {
                    return;
                }
                switch (level) {
                    case LogLevel.Error:
                        console.error(message);
                        return;
                    case LogLevel.Info:
                        console.info(message);
                        return;
                    case LogLevel.Verbose:
                        console.debug(message);
                        return;
                    case LogLevel.Warning:
                        console.warn(message);
                        return;
                    default:
                        return;
                }
            },
        },
    },
};

export const loginRequest = {
    scopes: [],
};
