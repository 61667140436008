import { PublicClientApplication, EventType,InteractionRequiredAuthError} from '@azure/msal-browser';
import { MsalProvider,AuthenticatedTemplate }from '@azure/msal-react';
import { msalConfig } from './authConfig';
import {Link,Route,Routes} from "react-router-dom";
import {Home} from './Home';
import {Survey} from './Survey';
import {Backend} from './Backend';
import {useState,useEffect} from "react";
import { Helmet } from 'react-helmet';
import './Appcss.css';

const msalInstance = new PublicClientApplication(msalConfig);
await msalInstance.initialize().then(()=>{});
msalInstance.enableAccountStorageEvents();
try {
if (!msalInstance.getActiveAccount() && msalInstance.getAllAccounts().length > 0) {
	msalInstance.setActiveAccount(msalInstance.getActiveAccount()[0]);
}

msalInstance.addEventCallback((event) => {
    if (event.eventType === EventType.LOGIN_SUCCESS && event.payload.account) {
        const account = event.payload.account;
        msalInstance.setActiveAccount(account);
    }
});
} catch(error) {

}
async function signInUser(){
	await msalInstance.loginRedirect({
        	scopes: ['https://gnsgvoportal.accenture.com/GVO.Role']
	}).then(() => {
	});
	
}

const signOutUser =()=>{
	const requestOptions = {
        	method: 'POST',
	        headers: { 'Content-Type': 'application/json' },
	        body: JSON.stringify({'Logout': msalInstance.getActiveAccount().name})
        };
     	fetch('https://gnsgvo.accenture.com/api/log',requestOptions);
	msalInstance.logoutRedirect();
}

function App() {
	const [username,setUsername]=useState(' ');
	const currentAccount=msalInstance.getActiveAccount();
	var GVOrole=false;
	var Surveyrole=false;
	useEffect( ()=>{
		if (currentAccount){
			setUsername(currentAccount.name);
		} else {
		async function signInSSOUsertry() {
			  try {
		                const itemKey = "msal.interaction.status";
                    		if (sessionStorage.getItem(itemKey)) {
		                      sessionStorage.removeItem(itemKey);
                		    }

				  await msalInstance.ssoSilent({
            				scopes: ["https://gnsgvoportal.accenture.com/GVO.Role"],
	/*			            loginHint: "jipin.chandran@accenture.com"*/
				        }).then(response => {
			                	if (response.account.name){
							setUsername(response.account.name);
							msalInstance.setActiveAccount(msalInstance.getAllAccounts()[0]);
			               		 } else {
							setUsername('Guest User');
						}
	        			});

		 } catch (err) {
			if (err instanceof InteractionRequiredAuthError) {
				const itemKey = "msal.interaction.status";
				if (sessionStorage.getItem(itemKey)) {
                                      sessionStorage.removeItem(itemKey);
                                    }

				await msalInstance.loginPopup({scopes: ["https://gnsgvoportal.accenture.com/GVO.Role"]}).then(response =>{
					if (msalInstance.getActiveAccount()){setUsername("pop-up");}}).catch(error => {console.log("Internal Pop up error is: "+error);
						msalInstance.loginRedirect({
				                scopes: ['https://gnsgvoportal.accenture.com/GVO.Role']
					        }).then(() => {
				        		}).catch(error => {});
					});
			} else {
			
			}
	
			}
		console.log('reached:');}
		signInSSOUsertry();
		}
	},[currentAccount,username]);
   if (currentAccount){
      for (let i = 0; i < (currentAccount.idTokenClaims.roles).length; i++) {
         if (currentAccount.idTokenClaims.roles[i].localeCompare('GVO_Role')===0){
                       GVOrole=true;
         }
	 if (currentAccount.idTokenClaims.roles[i].localeCompare('Survey_only')===0){
                       Surveyrole=true;
         }
      }  
  } else {
 
  }

  if(GVOrole){
     const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({'Login_GVO': currentAccount.name})
    };
   fetch('https://gnsgvo.accenture.com/api/log',requestOptions);
   return (
 	<MsalProvider instance={msalInstance}>
	   <Helmet> <title> CCaaS Operations</title></Helmet>    
	   <h1 style={{ marginLeft: "0.5rem", backgroundImage: "url(/logoAcc.png)",backgroundSize: "30% 100%",backgroundRepeat: "no-repeat",backgroundPosition: "right top"}}> CCaaS Operations! welcome {username}</h1>
	  <nav>
 	   <ul className="topnav">
	    <AuthenticatedTemplate>
		     <li className="topnavlink"><Link to="/">Home </Link></li>
		     <li className="topnavlink"><Link to="/Backend" state={{resource:'Accounts'}}>Accounts</Link></li>
		     <li className="topnavlink"><Link to="/Backend" state={{resource:'Users'}}>Users </Link></li>
		     <li className="topnavlink"><Link to="/Backend" state={{resource:'DID'}}>Phone#s</Link></li>
		     <li className="topnavlink"><Link to="/Backend" state={{resource:'TFN'}}>TFNs</Link></li>
		     <li className="topnavlink"><Link to="/Backend" state={{resource:'Bills'}}>Bills</Link></li>
        	     <li className="topnavlink"><Link to="/Backend" state={{resource:'Services'}}>AWS Services</Link></li>
		     <li className="topnavlink"><Link to="/Backend" state={{resource:'Features'}}>Features List</Link></li>
		     <li className="topnavlink"><Link to="/Backend" state={{resource:'Survey'}}>SurveyResult</Link></li>
	   	 <li className="topnavlink"><Link to="/survey">Survey </Link></li>
	     <li className="topnavbutton"><button onClick={signOutUser} type="button" >Sign Out</button></li>
	    </AuthenticatedTemplate>
	   </ul>
	  </nav> 
	  <Routes>
	   <Route path="/" element={<Home />} />
	   <Route path="/survey" element={<Survey />} />
	   <Route path="/Backend" element={<Backend />} />
 	  </Routes> 
	</MsalProvider>
  );
  } else if (Surveyrole){
      const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({'Login_Survey': currentAccount.name})
     };
   fetch('https://gnsgvo.accenture.com/api/log',requestOptions);
	   return (
        <MsalProvider instance={msalInstance}>
 	 <Helmet> <title> CCaaS Operations</title></Helmet>
               <h1 style={{ marginLeft: "0.5rem", backgroundImage: "url(/logoAcc.png)",backgroundSize: "30% 100%",backgroundRepeat: "no-repeat",backgroundPosition: "right top"}}> CCaaS Operations! welcome {username}</h1>
           <AuthenticatedTemplate>
	    <nav>
	     <ul className="topnav">
               <li className="topnavbutton"><button onClick={signOutUser} type="button" >Sign Out</button></li>
	     </ul>
	    </nav>
		<Survey />
           </AuthenticatedTemplate>
          <Routes>
           <Route path="/" element={<Home />} />
           <Route path="/survey" element={<Survey />} />
          </Routes>
        </MsalProvider>
  );
  } else {
	  return(
		 <MsalProvider instance={msalInstance}>
		  <Helmet> <title> CCaaS Operations</title></Helmet>
		    <h1 style={{ marginLeft: "0.5rem", backgroundImage: "url(/logoAcc.png)",backgroundSize: "30% 100%",backgroundRepeat: "no-repeat",backgroundPosition: "right top"}}> CCaaS Operations! welcome {username} </h1>
		    <nav> <ul className="topnav">
                       <li className="topnavlink"><button onClick={signInUser} type="button" >Sign In</button></li>
                       </ul>
            	    </nav>
		  <h4> This portal is meant for Accenture Internal usage only</h4> 
		 </MsalProvider>
		);
	  }
}

export default App;
